/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState, useCallback } from "react";

import { ActionButtons, THead } from "./components";
import { TableContainer } from "./styles";

interface Request {
  actions?: boolean;
  tbody: Array<string | string[]>[];
  thead: string[];
  tbuttons: string[];
  tuploads: string[];
  handleExistingValues: Function;
}

const Table: React.FC<Request> = ({
  actions = true,
  tuploads,
  tbody,
  thead,
  tbuttons,
  handleExistingValues,
}) => {
  const [arrayIndex, setArrayIndex] = useState<number>();
  const [isEmptyTable, setIsEmptyTable] = useState<boolean>(false);
  const token = localStorage.getItem("token");
  const auth = `Bearer ${token}`;

  const verifyIfIsAnEmptyTable = useCallback(() => {
    let isEmpty = true;
    if (tbody.length !== 1) {
      return;
    }

    tbody[0].forEach((item) => {
      if (item !== "") {
        isEmpty = false;
      }
    });

    setIsEmptyTable(isEmpty);
  }, [tbody]);

  useEffect(() => {
    (() => {
      thead.forEach((header, index) =>
        tuploads.forEach((upload) => {
          if (upload === header) setArrayIndex(index);
        })
      );
    })();

    verifyIfIsAnEmptyTable();
  }, [thead, tuploads, verifyIfIsAnEmptyTable]);

  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            {actions ? <th>Ações</th> : null}
            {thead.map((head) => (
              <THead label={head} />
            ))}
          </tr>
        </thead>
        <tbody>
          {!isEmptyTable &&
            tbody.map((body) => (
              <tr>
                {actions ? (
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "35%",
                    }}
                  >
                    {tbuttons.map((buttonType) => (
                      <ActionButtons
                        buttonType={buttonType}
                        handleExistingValues={handleExistingValues}
                        id={parseInt(
                          typeof body[0] === "object" ? body[0][0] : body[0],
                          10
                        )}
                      />
                    ))}
                  </td>
                ) : null}

                {body.map((item, index) =>
                  index === arrayIndex ? (
                    <td>
                      {typeof item === "object" ? (
                        item.map((element, index2) => (
                          <>
                            <a
                              href={`${element}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Anexo {index2 + 1}
                            </a>
                            <br />
                          </>
                        ))
                      ) : (
                        <a href={`https://pmsbjcz.semv.com.br/admin/${item}`}>
                          {item}
                        </a>
                      )}
                    </td>
                  ) : (
                    <td>{item}</td>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default Table;
