import { Scope } from "@unform/core";
import React, { useState, useCallback, useEffect } from "react";

import Select from "../../../../components/Select";
import api from "../../../../service/api";

interface SelectOptions {
  label: string;
  value: string | number;
}
interface IURL {
  url: string;
}
const UserGroups: React.FC<IURL> = ({ url }) => {
  const [userGroups, setUserGroups] = useState<SelectOptions[]>([]);

  const recoverUserGroups = useCallback(async () => {
    // console.log(url);
    const { data } = url ? await api.get(url) : await api.get("/pages/groups");
    const { tbody } = data.content.table;
    const options = tbody.map((elemento: string[]) => {
      return {
        value: elemento[0],
        label: elemento[1],
      };
    });

    setUserGroups(options);
  }, []);

  useEffect(() => {
    recoverUserGroups();
  }, [recoverUserGroups]);

  return (
    <Scope path="groupId">
      <Select name="groups" placeholder=" " options={userGroups} />
    </Scope>
  );
};

export default UserGroups;
