export const uploadFile = async (files: File[]) => {
  const dataForm = new FormData();
  const names: Array<string> = [];
  [...files].forEach((file) => {
    names.push(file.name);
    dataForm.append("file", file);
  });

  const res = await fetch(`https://pmsbjcz.semv.com.br/uploads/`, {
    method: "POST",
    body: dataForm,
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }),
  });
  return names;
};
