import React, { useCallback, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

import api from "../../../service/api";

import { ConfirmWindow } from "../../ConfirmWindow";
import { showToast } from "../../CustomToast";

import { ActionButton } from "../ActionButton";
import Request from "../dto";

import { useForceUpdate } from "../../../hooks";

const Delete: React.FC<Omit<Request, "existingValues">> = ({ id }) => {
  const { update } = useForceUpdate();
  const params: { submenu: string } = useParams();

  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const handleMessage = useCallback(() => {
    setShowConfirm(!showConfirm);
  }, [showConfirm]);

  const handleDelete = useCallback(async () => {
    await api.delete(`/saneamento/admin/cadastro/${params.submenu}/${id}`);

    showToast({
      type: "success",
      message: `Registro com código ${id} de ${params.submenu} removido`,
    });

    update();

    handleMessage();
  }, [params, id, handleMessage, update]);

  return (
    <>
      <Tooltip title="Excluir">
        <div>
          <ActionButton onClick={handleMessage}>
            <FaRegTrashAlt color="#f44336" />
          </ActionButton>
        </div>
      </Tooltip>
      {showConfirm && (
        <ConfirmWindow
          doAfterCancel={handleMessage}
          doAfterConfirm={handleDelete}
          text="Deseja realmente excluir o item?"
        />
      )}
    </>
  );
};

export default Delete;
