import React from "react";
import { VscPreview } from "react-icons/vsc";

import Tooltip from "@material-ui/core/Tooltip";
import { useParams, Link } from "react-router-dom";

import { ActionButton } from "../ActionButton";

import Request from "../dto";

const Show: React.FC<Omit<Request, "existingValues">> = ({ id }) => {
  const params: { id: string; submenu: string } = useParams();
  return (
    <Tooltip title="Visualizar">
      <Link to={`/modulos/${params.id}/${params.submenu}/${id}`}>
        <ActionButton>
          <VscPreview height="15px" width="15px" color="teal" />
        </ActionButton>
      </Link>
    </Tooltip>
  );
};

export default Show;
