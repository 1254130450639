import React from "react";

import { PropertieInfo } from "../../styles";

interface FilesPreviewProps {
  files: string[];
}

export const FilesPreview: React.FC<FilesPreviewProps> = ({ files }) => {
  return (
    <div>
      <h1>Anexos</h1>
      <PropertieInfo>
        {files.length > 0 ? (
          files.map((element) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "200px",
                width: "100%",
                maxWidth: "200px",
                marginLeft: "10px",
                justifyContent: "space-between",
                overflowWrap: "break-word",
              }}
            >
              <img src={element} alt="Preview" width="250" height="210" />
            </div>
          ))
        ) : (
          <h4>Nenhum arquivo adicionado até o momento</h4>
        )}
      </PropertieInfo>
    </div>
  );
};
