import React, { useCallback, useState, useRef, useEffect } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import Title from "../../../components/Title";

import { Wrapper, Container, ButtonBuscar, CheckBoxOption } from "./styles";

import api from "../../../service/api";
import MenuCheckBox from "./CheckboxMenu";
import UserGroups from "./UserGroups";
import {
  IGroup,
  ISubMenuPermissions,
  IResponse,
  IFunctional,
} from "./interfaces";

import { showToast } from "../../../components";

const LiberacaoAcesso: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [visibleCheckBox, setVisibleCheckBoxes] = useState(false);
  const [idGroup, setIdGroup] = useState<IGroup>();
  const [response, setResponseState] = useState<IResponse>();
  const [buttonsResponse, setButtonsResponse] = useState<IFunctional>();

  const handleUsersPermission = async (id: IGroup) => {
    const { data } = await api.get(
      `/pages/acess?table=false&id_grupo_usuario=${id.groups}`
    );

    const permissions: ISubMenuPermissions[] = data.content;

    const listOfPermissions = permissions.map((element) => element);

    setVisibleCheckBoxes(true);

    formRef.current?.reset();

    if (listOfPermissions) {
      listOfPermissions.forEach((element) => {
        formRef.current?.setFieldValue(
          `moduleValues.menuValues.submenuValues.${element.id_sub_menu}`,
          [element.id_sub_menu.toString()]
        );
        // possivel problema qndo n tiver nenhuma permissao do Id no banco
        if (element.actions.length > 0) {
          element.actions.forEach((e) => {
            formRef.current?.setFieldValue(
              `moduleValues.menuValues.submenuValues.options.${
                element.id_sub_menu + e
              }`,
              [element.id_sub_menu + e.toString()]
            );
          });
        }
        if (element.functional_buttons.length > 0) {
          element.functional_buttons.forEach((e) => {
            formRef.current?.setFieldValue(
              `moduleValues.menuValues.submenuValues.options.${
                element.id_sub_menu + e
              }`,
              [element.id_sub_menu + e.toString()]
            );
          });
        }
      });
    }
  };

  const sendSelectedFields = async (
    id: any,
    data: { [key: string]: string | undefined[] | [] }
  ) => {
    const idSelectedFields: number[] = [];
    const actionSelected: string[] = [];
    const keys = Object.keys(data);

    const temp = Object.values(data.options);
    temp.forEach((e) => {
      if (e && e?.length > 0) actionSelected.push(e);
    });

    keys.forEach((key: any) => {
      const value = data[key][0];
      if (value) {
        idSelectedFields.push(parseInt(value, 10));
      }
    });

    const payload = {
      id_grupo_usuario: id,
      id_sub_menu: idSelectedFields,
      actions: actionSelected,
    };

    await api.post(`pages/permissions/${id.groups}`, payload);
  };

  const handleSubmit = async (data: any) => {
    if (idGroup?.groups) {
      console.log(idGroup?.groups);
    }
    if (idGroup?.groups && data.moduleValues.menuValues.submenuValues) {
      try {
        setLoading(true);
        await sendSelectedFields(
          idGroup?.groups,
          data.moduleValues.menuValues.submenuValues
        );
        showToast({
          type: "success",
          message: `Permissões atualizadas`,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSearch = useCallback(async (data: any) => {
    const id: IGroup = data.groupId;

    if (id.groups.toString() === "") return;
    setVisibleCheckBoxes(true);
    handleUsersPermission(id);
    setIdGroup(id);

    // mudar essa rota para identificar q é a criação de menus
  }, []);

  const fetchPermissions = useCallback(async () => {
    const responseFromApi = await api.get(`/pages/permissions`);
    const { data } = await api.get(`/pages/functional-buttons?table=false`);
    const allButtons: IFunctional = data.content;
    const allFields: IResponse = responseFromApi.data;

    setButtonsResponse(allButtons);
    setResponseState(allFields);
  }, []);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  return (
    <>
      <Title
        title="Liberação de Acesso"
        style={{ borderBottom: "1px solid #ccc", paddingLeft: "10px" }}
      />

      <Wrapper>
        <Container>
          <span style={{ marginTop: 15 }}>Tipo/Grupo de Usuário</span>
          <Form
            onSubmit={handleSearch}
            style={{
              display: "flex",
              width: 500,
            }}
            ref={formRef}
          >
            <UserGroups />
            <ButtonBuscar style={{ marginLeft: 10 }} type="submit">
              Buscar
            </ButtonBuscar>
          </Form>
          <br />
          <Form
            onSubmit={handleSubmit}
            style={{
              display: visibleCheckBox ? "flex" : "none",
            }}
            ref={formRef}
          >
            <CheckBoxOption>
              {response && buttonsResponse ? (
                <>
                  <MenuCheckBox
                    response={response}
                    buttons={buttonsResponse}
                    formRef={formRef}
                  />
                  <ButtonBuscar type="submit" disabled={loading}>
                    {" "}
                    {loading ? "Carregando" : "Salvar"}
                  </ButtonBuscar>
                </>
              ) : null}
            </CheckBoxOption>
          </Form>
        </Container>
      </Wrapper>
    </>
  );
};

export default LiberacaoAcesso;
