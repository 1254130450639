/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint no-param-reassign: ["error", { "props": false }] */
import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from "react";

import { FormHandles, useField } from "@unform/core";

import { Container, InputBlock, InputField } from "./styles";

interface Props {
  name: string;
  formRef?: React.RefObject<FormHandles>;
}

type InputProps = JSX.IntrinsicElements["input"] & Props;

const ImageInput: React.FC<InputProps> = ({ name, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const [filesList, setFilesList] = useState([] as any);

  const handlePreview = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      let file = {} as any;
      const fileList = [];
      if (files) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < files.length; i++) {
          file = files[i];
          const previewURLs = URL.createObjectURL(file);
          fileList.push(previewURLs);
          setPreview(previewURLs);
        }
        setFilesList(fileList);
        // const previewURL = URL.createObjectURL(files[0]);
        // setPreview(previewURL);
      } else setPreview(null);
    },
    []
  );

  // const handlePreview1 = useCallback(
  //   async (e: ChangeEvent<HTMLInputElement>) => {
  //     const file = e.target.files;
  //     if (file) {
  //       setPreview(file);
  //     }
  //     setPreview(null);
  //   },
  //   []
  // );

  // useEffect(() => {
  //   registerField({
  //     name: fieldName,
  //     ref: inputRef.current,
  //     path: "files",
  //     clearValue(ref: HTMLInputElement) {
  //       ref.value = "";
  //       setPreview(null);
  //     },
  //     setValue(_: HTMLInputElement, value: string) {
  //       setPreview(value);
  //     },
  //   });
  // }, [fieldName, registerField]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files",
      clearValue(ref: HTMLInputElement) {
        ref.value = "";
        setPreview(null);
      },
      // setValue(_: HTMLInputElement) {
      //   // setPreview(_.files);
      //   return _.files && Array.from(_.files);
      // },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {/* <InputBlock>
        <label htmlFor="preview">Anexos</label>
        <input
          multiple
          id="preview"
          type="file"
          ref={inputRef}
          onChange={handlePreview}
          {...rest}
        />
      </InputBlock> */}
      <>
        <label htmlFor="preview">Anexos</label>
        <input
          multiple
          type="file"
          ref={inputRef}
          onChange={handlePreview}
          {...rest}
        />

        {preview &&
          filesList?.map((element: string | undefined) => (
            <img src={element} alt="Preview" width="60" height="45" />
          ))}
      </>
    </Container>
  );
};

export default ImageInput;
