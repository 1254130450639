import React, { useCallback, useState } from "react";
import ReactLoading from "react-loading";
import { saveAs } from "file-saver";
import { FaRegFilePdf } from "react-icons/fa";

import { Container, Button } from "./styles";

import { MapIframe } from "../../../../../../components";
import api, { url } from "../../../../../../service/api";

interface ActionsProps {
  id: string;
}

interface IIframe {
  url: string;
  password: string;
}

export const Actions: React.FC<ActionsProps> = ({ id }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [iframe, setIframe] = useState<IIframe>();

  const handleCloseIFrame = useCallback(() => {
    setIframe(undefined);
  }, []);

  const handlePdf = useCallback(async () => {
    try {
      setLoading(true);
      const laudoPayload = {
        id,
      };

      const { data } = await api.post("/documents/patrimony", laudoPayload);
      setTimeout(async () => {
        const laudo = await api.get(`/documents/patrimony/${data}`, {
          responseType: "blob",
        });
        const laudoBloc = new Blob([laudo.data], { type: "application/pdf" });

        saveAs(laudoBloc, `patrimonio-${Date.now()}.pdf`);

        await api.delete(`/documents/patrimony/${data}`);
      }, 1000);
    } finally {
      setLoading(false);
    }
  }, [id]);

  if (iframe) {
    return (
      <MapIframe
        password={iframe.password}
        url={iframe.url}
        handleCloseIframe={handleCloseIFrame}
      />
    );
  }

  return (
    <Container>
      <Button type="button" onClick={handlePdf}>
        <FaRegFilePdf size={50} />
        Gerar PDF
        {loading && <ReactLoading height="20px" width="20px" color="teal" />}
      </Button>
    </Container>
  );
};
