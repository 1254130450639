import React, { useCallback, useState } from "react";
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";
import { saveAs } from "file-saver";
import api from "../../service/api";
import { IDefaultTable } from "../../interfaces/IDefaultPages";

import { Loading } from "../Loading";
import { ConfirmPDF } from "./components";
import { ICreatePdfRequest, ITablePDF } from "./types";

import { Container, ReportButton } from "./styles";

const buttons = [
  {
    color: "#5296A5",
    label: "CSV",
    Icon: <AiOutlineFileExcel size={22} />,
  },
  {
    color: "#72195A",
    label: "PDF",
    Icon: <AiOutlineFilePdf size={22} />,
  },
];

interface ReportsProps {
  table: IDefaultTable;
}

const Reports: React.FC<ReportsProps> = ({ table }) => {
  const [loading, setLoading] = useState<string>();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const handleCSV = useCallback(async () => {
    try {
      const csvPayload = {
        body: table.tbody,
        header: table.thead,
      };

      const { data } = await api.post("/documents/standard/csv", csvPayload);
      const csv = await api.get(`/documents/standard/csv/${data}`, {
        responseType: "blob",
      });

      const csvBlob = new Blob([csv.data], { type: "text/csv" });

      saveAs(csvBlob, `relatorio-patrimonial-${Date.now()}.csv`);

      setTimeout(async () => {
        api.delete(`/documents/standard/csv/${data}`, {
          responseType: "blob",
        });
      }, 1000);
    } finally {
      setLoading(undefined);
    }
  }, [table]);

  const toggleDialogState = useCallback(() => setShowDialog(!showDialog), [
    showDialog,
  ]);

  const handlePDF = useCallback(
    async (reportOrientation: "portrait" | "landscape") => {
      try {
        toggleDialogState();
        const content: ITablePDF[] = [
          {
            content: {
              body: table.tbody,
              header: table.thead,
            },
            title: "Relatório resumido",
            type: "table",
          },
        ];
        const documentPayload: ICreatePdfRequest = {
          cityName: "Carambeí",
          // content,
          contentTable: content,
          documentOrientation: reportOrientation,
        };
        const { data } = await api.post(
          "/documents/standard/pdf",
          documentPayload
        );
        setTimeout(async () => {
          const pdf = await api.get(`/documents/standard/pdf/${data}`, {
            responseType: "blob",
          });
          const pdfBlob = new Blob([pdf.data], { type: "application/pdf" });

          saveAs(pdfBlob, `relatorio-patrimonial-${Date.now()}.pdf`);

          await api.delete(`/documents/standard/pdf/${data}`);
        }, 1000);
      } finally {
        setLoading(undefined);
      }
    },
    [table, toggleDialogState]
  );

  const onClick = useCallback(
    (type: string) => {
      setLoading(type);

      if (type === "CSV") {
        handleCSV();

        return;
      }
      toggleDialogState();
    },
    [handleCSV, toggleDialogState]
  );

  return (
    <Container>
      {buttons.map((button) => (
        <ReportButton
          teste="teste"
          color={button.color}
          onClick={() => onClick(button.label)}
        >
          {loading && loading === button.label && <Loading />}
          {button.Icon}
          <p>{button.label}</p>
        </ReportButton>
      ))}
      {showDialog && (
        <ConfirmPDF
          doAfterCancel={toggleDialogState}
          doAfterConfirm={handlePDF}
        />
      )}
    </Container>
  );
};
export default Reports;
