import axios from "axios";

export const url = "https://pmsbjcz.semv.com.br/";
// export const url = "http://itrjgv.semv.com.br/";
// export const url = "http://www.semvconsultoria.com.br:8085";

const api = axios.create({
  baseURL: url,
});

export default api;
