/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, FormEvent, useState } from "react";

import { AiOutlineFilePdf } from "react-icons/ai";
import { MdCancel } from "react-icons/md";

import { MainButton } from "../../../MainButton";
import { ModalBackground } from "../../../ModalBackground";
import { Container } from "./styles";
// import Input from "../../../Input";

interface ConfirmPDFProps {
  doAfterConfirm(orientation: "portrait" | "landscape"): void;
  doAfterCancel(): void;
}

type Orientation = "landscape" | "portrait";

export const ConfirmPDF: React.FC<ConfirmPDFProps> = ({
  doAfterCancel,
  doAfterConfirm,
}) => {
  const [orientation, setOrientation] = useState<Orientation>("landscape");
  const handleRadioChange = useCallback((e: FormEvent<HTMLFormElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { value } = e.target;

    setOrientation(value);
  }, []);

  return (
    <ModalBackground>
      <Container>
        <div style={{ paddingBottom: "25px" }}>
          <h4>Escolha a orientação do documento:</h4>
        </div>
        <form
          onChange={handleRadioChange}
          style={{ alignItems: "center", paddingBottom: "25px" }}
        >
          <label htmlFor="orientation" style={{ marginRight: "10px" }}>
            Paisagem
          </label>
          <input
            type="radio"
            id="landscape"
            value="landscape"
            name="radAnswer"
            style={{ marginRight: "10px" }}
          />
          <label htmlFor="orientation" style={{ marginRight: "10px" }}>
            Retrato
          </label>
          <input type="radio" id="portrait" value="portrait" name="radAnswer" />
        </form>
        <div style={{ justifySelf: "flex-end" }}>
          <MainButton type="button" onClick={() => doAfterConfirm(orientation)}>
            <AiOutlineFilePdf />
            Gerar
          </MainButton>
          <MainButton
            type="button"
            onClick={doAfterCancel}
            buttonColor="#f44336"
          >
            <MdCancel />
            Cancelar
          </MainButton>
        </div>
      </Container>
    </ModalBackground>
  );
};
