import React from "react";

import { Title, Container } from "../../../components";

const Dashboard: React.FC = () => {
  return (
    <Container>
      <Title title="Dashboard" />
      <div style={{ marginLeft: "15px" }}>
        <a href="https://pmsbjcz.semv.com.br/">Acesse a página do PMSB</a>
      </div>
      <div style={{ marginLeft: "15px", marginTop: "15px" }}>
        <a href="https://formjcz.semv.com.br/">
          Acesse a página de formulários
        </a>
      </div>
    </Container>
  );
};

export default Dashboard;
