import React, { useState, useEffect, useCallback } from "react";
import { Switch, Route } from "react-router-dom";

import { IconName } from "@fortawesome/fontawesome-common-types";
import api from "../../../service/api";

import { Frame } from "../../../components";
import { MenuButtons, HomeButton } from "./components";
import { routes } from "./routes";

interface Modulos {
  [key: string]: {
    icon: IconName;
    id: string;
  };
}

const DefaultFrame: React.FC = () => {
  const [modules, setModules] = useState<Modulos>({});
  const renderMenuButtons = () => {
    const keys = Object.keys(modules);
    return keys.map((key) => (
      <MenuButtons module={key} id={modules[key].id} icon={modules[key].icon} />
    ));
  };

  const fetchMenus = useCallback(async () => {
    const { data }: { data: Modulos } = await api.get("/menus");
    setModules(data);
  }, []);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  return (
    <>
      <Frame
        content={
          <Switch>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
        }
        menu={
          <div>
            <HomeButton />
            {renderMenuButtons()}
          </div>
        }
      />
    </>
  );
};

export default DefaultFrame;
