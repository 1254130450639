import React, { useCallback, useState, useRef, useEffect } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import Title from "../../../components/Title";

import { Wrapper, Container, ButtonBuscar, CheckBoxOption } from "./styles";

import api from "../../../service/api";
import MenuCheckBox from "./CheckboxMenu";
import UserGroups from "./UserGroups";
import {
  IGroup,
  IFunctional,
  IModulePermission,
  IResponseApp,
} from "./interfaces";

import { showToast } from "../../../components";

const LiberacaoAcessoApp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [visibleCheckBox, setVisibleCheckBoxes] = useState(false);
  const [idGroup, setIdGroup] = useState<IGroup>();
  const [response, setResponseState] = useState<IResponseApp[]>();
  const [buttonsResponse, setButtonsResponse] = useState<IFunctional>();
  const url = "/app/groups";

  const handleUsersPermission = async (id: IGroup) => {
    const { data } = await api.get(
      `/app/acess?table=false&id_grupo_usuario=${id.groups}`
    );

    const permissions: IModulePermission[] = data.content;

    setVisibleCheckBoxes(true);

    formRef.current?.reset();

    if (permissions) {
      permissions.forEach((element) => {
        // possivel problema qndo n tiver nenhuma permissao do Id no banco
        if (element.actions.length > 0) {
          element.actions.forEach((e) => {
            formRef.current?.setFieldValue(
              `moduleValues.actions.${element.mod_id + e}`,
              [element.mod_id + e.toString()]
            );
          });
        }
        if (element.actions.length === element.total_actions) {
          formRef.current?.setFieldValue(`moduleValues.${element.mod_id}`, [
            element.mod_id.toString(),
          ]);
        }
      });
    }
  };

  const sendSelectedFields = async (
    id: any,
    data: { [key: string]: string | undefined[] | [] }
  ) => {
    const actionSelected: string[] = [];
    const temp = Object.values(data.actions);
    temp.forEach((e) => {
      if (e && e?.length > 0) actionSelected.push(e);
    });
    const payload = {
      id_grupo_usuario: id,
      actions: actionSelected,
    };
    await api.post(`app/permissions/${id.groups}`, payload);
  };

  const handleSubmit = async (data: any) => {
    if (idGroup?.groups && data.moduleValues) {
      try {
        setLoading(true);
        await sendSelectedFields(idGroup?.groups, data.moduleValues);
        showToast({
          type: "success",
          message: `Permissões atualizadas`,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSearch = useCallback(async (data: any) => {
    const id: IGroup = data.groupId;

    if (id.groups.toString() === "") return;
    setVisibleCheckBoxes(true);
    handleUsersPermission(id);
    setIdGroup(id);

    // mudar essa rota para identificar q é a criação de menus
  }, []);

  const fetchPermissions = useCallback(async () => {
    const responseFromApi = await api.get(`/app/permissions`);
    const allFields: IResponseApp[] = responseFromApi.data;
    setButtonsResponse({});
    setResponseState(allFields);
  }, []);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  return (
    <>
      <Title
        title="Liberação de Acesso ao Aplicativo"
        style={{ borderBottom: "1px solid #ccc", paddingLeft: "10px" }}
      />

      <Wrapper>
        <Container>
          <span style={{ marginTop: 15 }}>Tipo/Grupo de Usuário</span>
          <Form
            onSubmit={handleSearch}
            style={{
              display: "flex",
              width: 500,
            }}
            ref={formRef}
          >
            <UserGroups url={url} />
            <ButtonBuscar style={{ marginLeft: 10 }} type="submit">
              Buscar
            </ButtonBuscar>
          </Form>
          <br />
          <Form
            onSubmit={handleSubmit}
            style={{
              display: visibleCheckBox ? "flex" : "none",
            }}
            ref={formRef}
          >
            <CheckBoxOption>
              {response && buttonsResponse ? (
                <>
                  <MenuCheckBox
                    response={response}
                    buttons={buttonsResponse}
                    formRef={formRef}
                  />
                  <ButtonBuscar type="submit" disabled={loading}>
                    {" "}
                    {loading ? "Carregando" : "Salvar"}
                  </ButtonBuscar>
                </>
              ) : null}
            </CheckBoxOption>
          </Form>
        </Container>
      </Wrapper>
    </>
  );
};

export default LiberacaoAcessoApp;
