import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  margin-right: 15px;
  top: 11%;
  right: 0;

  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border-radius: 3px;

  box-shadow: 0px 0px 15px -5px #666;
  padding: 10px;

  width: 120px;
`;

export const Button = styled.button`
  color: teal;
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;

  &:hover {
    color: white;
    background-color: teal;

    svg {
      color: white;
    }
  }
`;
