import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-common-types";

import api from "../../../service/api";

import { Loading } from "../../../components";
import { Button, ButtonsContainer, SubMenu } from "./styles";

interface SubMenu {
  icon: IconName;
  subMenu: string;
  link: string;
}

interface Menus {
  [key: string]: SubMenu[];
}

const Menus: React.FC = () => {
  const params: { id: string } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [menus, setMenus] = useState<Menus>();
  const [keys, setKeys] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [openSub, setOpenSubMenu] = useState<string>();

  const openSubMenu = useCallback(
    (subMenu: SubMenu[]) => {
      return (
        <SubMenu>
          {subMenu.map((_subMenu) => (
            <Link to={`/modulos/${params.id}/${_subMenu.link}`}>
              <button type="button" key={_subMenu.subMenu}>
                {_subMenu.subMenu}
              </button>
            </Link>
          ))}
        </SubMenu>
      );
    },
    [params]
  );

  const handleButtonClick = useCallback(
    (key: string) => {
      if (key === openSub) {
        setIsOpen(!isOpen);
      } else {
        setIsOpen(true);
      }

      setOpenSubMenu(key);
    },
    [isOpen, openSub]
  );

  const fetchMenus = useCallback(async () => {
    try {
      setLoading(true);
      const { data }: { data: Menus } = await api.get(
        `/menus?id=${atob(params.id)}`
      );
      setKeys(Object.keys(data));

      setMenus(data);
    } finally {
      setLoading(false);
    }
  }, [params.id]);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  if (loading) {
    return <Loading />;
  }

  return (
    <ButtonsContainer>
      {menus &&
        keys.map((key) => (
          <>
            <Button
              key={key}
              type="button"
              onClick={() => handleButtonClick(key)}
            >
              <FontAwesomeIcon
                icon={["fas", menus[key][0].icon]}
                color="teal"
                style={{ fontSize: "30px" }}
              />
              {key}
            </Button>
            {isOpen && openSub === key ? openSubMenu(menus[key]) : null}
          </>
        ))}
    </ButtonsContainer>
  );
};

export default Menus;
