/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { IDefaultFieldsContent } from "../../interfaces/IDefaultPages";

export const formatFields = (
  fields: IDefaultFieldsContent[],
  formData: any
): any => {
  fields.forEach((element) => {
    if (element.type === "number") {
      formData[element.metadata] =
        formData[element.metadata] === ""
          ? 0
          : parseFloat(formData[element.metadata]);
    }

    if (element.type === "date") {
      formData[element.metadata] =
        formData[element.metadata] === "" ? null : formData[element.metadata];
    }

    if (element.type === "boolean") {
      formData[element.metadata] = formData[element.metadata] === "true";
    }

    // if (element.type === "file") {
    //   // retornar o nome das imagens
    //   formData[element.metadata] = formData[element.metadata].name;
    // }
  });

  return formData;
};
