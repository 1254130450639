/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useCallback, useState } from "react";

import { useParams } from "react-router-dom";

import api from "../../../../service/api";

import { Actions, FilesPreview } from "./components";
import { Container, InfoProvider, PatrimonyInfo } from "./styles";
import { IPatrimonyInterface } from "../../../../interfaces/IPatrimony";

export const PatrimonyView: React.FC = () => {
  const params: { itemId: string; submenu: string } = useParams();

  const [preview, setPreview] = useState<string[]>([]);
  const [patrimonyData, setPatrimonyData] = useState<IPatrimonyInterface>(
    {} as IPatrimonyInterface
  );

  const fetchPropertie = useCallback(async () => {
    const { data } = await api.get(`pages/patrimony/${params.itemId}`);
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key] === "" || !data[key]) {
        data[key] = "------";
      }
    });
    setPatrimonyData(data);
    setPreview(data.Anexos);
  }, [params]);

  useEffect(() => {
    fetchPropertie();
  }, [fetchPropertie]);

  return (
    <Container>
      <Actions id={params.itemId} />
      <InfoProvider>
        <div>
          <h1>{patrimonyData.Item}</h1>

          <PatrimonyInfo>
            <div>
              <h3>Estado</h3>
              <p>{patrimonyData.Estado}</p>
            </div>
            <div>
              <h3>Descrição</h3>
              <p>{patrimonyData.Descrição}</p>
            </div>
            <div>
              <h3>Local</h3>
              <p>{patrimonyData.Local}</p>
            </div>
            <div>
              <h3>Secretaria</h3>
              <p>{patrimonyData.Secretaria}</p>
            </div>
            <div>
              <h3>Valor</h3>
              <p>{patrimonyData.Valor}</p>
            </div>
            <div>
              <h3>Plaqueta</h3>
              <p>{patrimonyData.Plaqueta}</p>
            </div>
            <div>
              <h3>Categoria</h3>
              <p>{patrimonyData.Categoria}</p>
            </div>
            <div>
              <h3>Criado em</h3>
              <p>{patrimonyData["Criado em"]}</p>
            </div>
            <div>
              <h3>Criado por</h3>
              <p>{patrimonyData["Criado por"]}</p>
            </div>
            <div>
              <h3>semvId</h3>
              <p>{patrimonyData.semvId}</p>
            </div>
          </PatrimonyInfo>
        </div>
        <FilesPreview files={preview} />
      </InfoProvider>
    </Container>
  );
};
