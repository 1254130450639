import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  height: 100%;

  input {
    margin-top: 15px;
    margin-left: 1px;
  }
  label {
    font-size: 18px;
    margin-left: 0px;
  }
  img {
    margin-left: 10px;
  }
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: row;

  input[type="file"] {
    display: none;
  }
  label {
    max-width: 185px;
    min-width: 175px;
    min-height: 45px;
    max-height: 50px;
    border: 1px solid #1a8080;
    color: #1a8080;
    background: #fff;
    margin-right: 15px;
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #1a8080;
      color: #fff;
      cursor: pointer;
    }
  }
`;

export const InputField = styled.div`
  font-size: 18px;
  padding: 10px;
  margin: 10px;
  background: papayawhip;
  border: none;
  border-radius: 3px;
  ::placeholder {
    color: palevioletred;
  }
`;
