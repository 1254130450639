import Dashboard from "../Dashboard";
import Menus from "../Menus";
import Consulta from "../DefaultPages/Consulta";
import { ModificarSenha } from "../DefaultPages";
import { PropertiesView, CreatePropertie } from "../CustomPages";
import LiberacaoAcesso from "../LiberacaoAcesso";
import LiberacaoAcessoApp from "../LiberacaoAcessoApp";
import { PatrimonyView } from "../CustomPages/PatrimonyView";

export const routes = [
  {
    component: Consulta,
    exact: true,
    path: "/comentarios",
  },
  {
    component: Dashboard,
    exact: true,
    path: "/dashboard",
  },
  {
    component: ModificarSenha, // TELA QUE VOCE IRA CRIAR
    exact: true,
    path: "/senha", // NOME DA ROTA QUE VOCE DESEJA
  },
  {
    component: LiberacaoAcesso,
    exact: true,
    path: "/modulos/:id/permissions",
  },
  {
    component: LiberacaoAcessoApp,
    exact: true,
    path: "/modulos/:id/app-permissions",
  },
  {
    component: LiberacaoAcesso,
    exact: true,
    path: "/modulos/:id/app",
  },
  {
    component: PropertiesView,
    exact: true,
    path: "/modulos/:id/propertie/:itemId",
  },
  {
    component: PatrimonyView,
    exact: true,
    path: "/modulos/:id/patrimony/:itemId",
  },
  {
    component: Consulta,
    exact: true,
    path: "/modulos/:id/:submenu",
  },
  {
    component: Menus,
    exact: true,
    path: "/modulos/:id",
  },
  {
    component: CreatePropertie,
    exact: true,
    path: "/propertie/:id",
  },
  {
    component: CreatePropertie,
    exact: true,
    path: "/propertie",
  },
];
