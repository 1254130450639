/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { FormHandles, Scope } from "@unform/core";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { Menu } from "./styles";
import { IFunctional, IResponseApp } from "../interfaces";
import CheckboxInput from "../../../../components/Checkbox";
import { ButtonPlus } from "../../../../components/QuickRegister/styles";

interface MenuCheckBoxProps {
  response: IResponseApp[];
  buttons?: IFunctional;
  formRef: React.RefObject<FormHandles>;
}

const MenuCheckBox: React.FC<MenuCheckBoxProps> = ({ response, formRef }) => {
  const [actionId, setActionId] = useState("");

  const selectChildren = async (
    checked: boolean,
    id: number,
    menu?: string
  ) => {
    if (menu) {
      if (checked) {
        formRef.current?.setFieldValue(`moduleValues.menuValues.${menu}`, [
          menu.toString(),
        ]);
        formRef.current?.setFieldValue(
          `moduleValues.menuValues.submenuValues.${id}`,
          [id.toString()]
        );
      } else {
        formRef.current?.clearField(`moduleValues.menuValues.${menu}`);
        formRef.current?.clearField(
          `moduleValues.menuValues.submenuValues.${id}`
        );
      }
    }
  };
  const showOptions = (id: string, myId: string) => {
    if (id === myId) return true;

    return false;
  };
  const renderSubMenuOptions = (module: IResponseApp) => {
    return (
      <Scope path="actions">
        <div
          style={{
            display: showOptions(module.id.toString(), actionId)
              ? "grid"
              : "none",
            marginLeft: 25,
          }}
        >
          {module.actions.map((index: string) => (
            <CheckboxInput
              name={module.id.toString() + index}
              options={[
                {
                  id: module.id.toString() + index,
                  value: `${module.id.toString()}/${index}`,
                  label: index,
                },
              ]}
            />
          ))}
        </div>
      </Scope>
    );
  };

  const collapseButton = () => {
    setActionId("null");
  };
  const expandButton = (id: number) => {
    setActionId(id.toString());
  };

  const ModuleCheckbox = (module: IResponseApp) => {
    const checkAll = (id: string, checked: boolean) => {
      // console.log(id);
      // console.log(checked);

      module.actions.forEach((index) => {
        if (checked) {
          formRef.current?.setFieldValue(
            `moduleValues.actions.${module.id + index}`,
            [module.id + index.toString()]
          );
        }
        if (!checked) {
          formRef.current?.clearField(
            `moduleValues.actions.${module.id + index}`
          );
        }
      });
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            marginRight: 10,
            borderRight: "solid",
            borderColor: "teal",
          }}
        >
          <CheckboxInput
            name={module.id.toString()}
            options={[
              {
                id: module.id.toString(),
                value: module.id.toString(),
                label: module.nome,
              },
            ]}
            doAfterChangeValue={checkAll}
          />

          <ButtonPlus type="button" onClick={() => expandButton(module.id)}>
            <AiOutlinePlus />
          </ButtonPlus>
          <ButtonPlus type="button" onClick={() => collapseButton()}>
            <AiOutlineMinus />
          </ButtonPlus>
        </div>
        {renderSubMenuOptions(module)}
      </div>
    );
  };

  const renderModules = () => {
    const modules = Object.values(response);
    return (
      <Scope path="moduleValues">
        {modules.map((module) => ModuleCheckbox(module))}
      </Scope>
    );
  };

  return <Menu>{renderModules()}</Menu>;
};

export default MenuCheckBox;
